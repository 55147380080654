import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";
import { analytics } from "@/lib/analytics/analytics.client";
import { OPENAI_API_KEYS_URL } from "@/lib/constants";
import { getFileHref } from "@/lib/path-utils";
import usePrevious from "@/lib/use-previous";
import { getPagedEvaluations } from "@/services/evaluationReports.service";
import { getFiles } from "@/services/files.service";
import {
  createApiKey,
  updateModelProviderKeys,
  useModelProviderKeys,
  useOrganizationApiKeys,
} from "@/services/organizations.service";
import { Dataset } from "@/types/app/dataset";
import { Prompt } from "@/types/app/prompt";
import Button from "@components/library/Button";
import { ArrowUpRightIcon, LoadingIcon } from "@components/library/Icons";
import { InlineLink } from "@components/library/InlineLink";
import { PageSectionHeader } from "@components/library/PageSectionHeader";
import { ConditionalTooltip } from "@components/library/Tooltip";
import { FormikTextInput } from "@components/molecules/FormikInput";
import { ArrowRightIcon } from "@heroicons/react/outline";
import { Form, Formik } from "formik";
import { isString } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useInterval } from "usehooks-ts";
import { API_URL_OVERRIDE, CodePreviewWithCopyButton } from "../Dashboard/CodeSnippet";

const DEFAULT_API_KEY_NAME = "getting-started";
export const ONBOARDING_PROMPT_NAME = "First name extraction";
export const ONBOARDING_DATASET_NAME = "First names";
const ONBOARDING_EVALUATION_NAME = "Example Evaluation";

interface Props {
  /* Callback for when the snippet has been completed or it has been skipped */
  onClose: () => void;
}

/** Guide with instructions for creating a Prompt.
 *
 * Shown to new users when they first enter Humanloop.
 */
export const OnboardingPromptEvaluationSnippet = ({ onClose }: Props) => {
  const [creatingApiKey, setCreatingApiKey] = useState<boolean>(false);
  const [apiKey, setApiKey] = useState<string | null>(null);
  const [openaiApiKey, setOpenaiApiKey] = useState<string | null>(null);

  const { organization, slug } = useActiveOrganization();
  const { keys } = useModelProviderKeys(organization?.id);
  const { apiKeys } = useOrganizationApiKeys(organization?.id);

  // Set the Humanloop API key if one was previously created.
  useEffect(() => {
    if (apiKeys && apiKey === null) {
      const key = apiKeys.find((key) => key.name === DEFAULT_API_KEY_NAME);
      if (key) {
        setApiKey(key.api_key);
      }
    }
  }, [apiKey, apiKeys]);
  // Set the OpenAI key if one was previously created.
  useEffect(() => {
    if (keys && openaiApiKey === null) {
      const key = keys.find((key) => key.model_provider === "openai");
      if (key) {
        setOpenaiApiKey(key.key);
      }
    }
  }, [openaiApiKey, keys]);

  const createNewApiKey = async () => {
    if (!organization) {
      throw new Error("Organization not found");
    }
    setCreatingApiKey(true);
    try {
      const response = await createApiKey({ name: DEFAULT_API_KEY_NAME, organization_id: organization?.id });
      setApiKey(response.data.api_key);
    } finally {
      setCreatingApiKey(false);
    }
  };

  // Monitor Datasets, Prompts and its Evaluations to check for progress
  const [prompt, setPrompt] = useState<Prompt | null>(null);
  const [dataset, setDataset] = useState<Dataset | null>(null);
  const [evaluationId, setEvaluationId] = useState<string | null>(null);

  useInterval(
    async () => {
      const prompts = (await getFiles({ fileTypes: ["prompt"], pageNumber: 1, name: ONBOARDING_PROMPT_NAME })).data
        .records;
      if (prompts.length > 0) {
        setPrompt(prompts[0] as Prompt);
      }
    },
    prompt === null ? 3000 : null,
  );
  useInterval(
    async () => {
      const datasets = (await getFiles({ fileTypes: ["dataset"], pageNumber: 1, name: ONBOARDING_DATASET_NAME })).data
        .records;
      if (datasets.length > 0) {
        setDataset(datasets[0] as Dataset);
      }
    },
    dataset === null ? 3000 : null,
  );

  useInterval(
    async () => {
      if (prompt !== null) {
        try {
          const evaluations = (await getPagedEvaluations({ fileId: prompt.id, page: 1, size: 1 })).records;
          if (evaluations.length > 0) {
            setEvaluationId(evaluations[0].id);
          }
        } catch (e) {
          // If request fails, we just ignore it and try again later
          console.error(e);
        }
      }
    },
    prompt !== null && evaluationId === null ? 1000 : null,
  );

  const evaluationHref =
    slug && prompt?.id && evaluationId
      ? `${getFileHref({ slug, id: prompt.id, page: "evaluations" })}/${evaluationId}/runs`
      : null;

  // Scroll to the [View Evaluation] button when it's enabled.
  const evaluationButtonRef = useRef<HTMLButtonElement>(null);
  const previousEvaluationHref = usePrevious(evaluationHref);
  useEffect(() => {
    if (evaluationHref && !previousEvaluationHref) {
      evaluationButtonRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [evaluationHref, previousEvaluationHref]);

  if (!organization) {
    return null;
  }

  return (
    <div className="flex flex-col gap-32">
      <div>
        <PageSectionHeader title="Install Humanloop SDK" size="sm" className="!mb-8" />
        <CodePreviewWithCopyButton language="bash" code="pip install humanloop openai" />
      </div>

      <div>
        <PageSectionHeader title="Create a Humanloop API key" size="sm" className="!mb-16" />
        {apiKey === null ? (
          <Button
            shade="black"
            styling="solid"
            size={28}
            onClick={() => {
              createNewApiKey();
              analytics.track("Button Clicked", {
                purpose: "create-humanloop-api-key",
                location: "onboarding",
              });
            }}
            disabled={creatingApiKey}
            IconRight={creatingApiKey ? LoadingIcon : undefined}
          >
            Create key
          </Button>
        ) : (
          <div className="flex w-full flex-col gap-6">
            <CodePreviewWithCopyButton language="text" code={apiKey} />
            {/* If the user refreshes the page, they lose access to the full previous API key.
              We thus have to allow them to create a new one.
              But it's still useful to just populate with the censored old key as the user might
              have saved it and don't want to be forced to create a new one.
          */}
            {apiKey.includes("*") && (
              <div>
                <Button
                  size={24}
                  onClick={createNewApiKey}
                  disabled={creatingApiKey}
                  IconRight={creatingApiKey ? LoadingIcon : undefined}
                >
                  Create new key
                </Button>
              </div>
            )}
          </div>
        )}
      </div>

      <div>
        <PageSectionHeader
          title={
            <>
              Add your{" "}
              <InlineLink target="_blank" href={OPENAI_API_KEYS_URL} IconRight={ArrowUpRightIcon}>
                OpenAI key
              </InlineLink>
            </>
          }
          size="sm"
          className=" !mb-12"
        />
        {openaiApiKey === null ? (
          <OpenAIApiKeyInput organizationId={organization.id} onSave={(key) => setOpenaiApiKey(key)} />
        ) : (
          <CodePreviewWithCopyButton language="text" code={openaiApiKey} />
        )}
      </div>

      <div>
        <PageSectionHeader
          title={
            <>
              Create <code className="font-normal">eval.py</code> and run this code to evaluate an example Prompt
            </>
          }
          size="sm"
          className="!mb-16"
        />
        <CodePreviewWithCopyButton
          language="python"
          code={getOnboardingSnippet({
            humanloopApiKey: apiKey,
            openaiApiKey: openaiApiKey,
          })}
          showLineNumbers
          lineNumberStyle={{ opacity: 0.3 }}
          onCopy={() => {
            analytics.track("Button Clicked", {
              purpose: "copy-snippet",
              location: "onboarding",
            });
          }}
        />
      </div>

      <div className="flex justify-between gap-24">
        <ConditionalTooltip condition={evaluationId === null} content="Run the code above to create an Evaluation">
          <Button
            IconRight={ArrowRightIcon}
            disabled={evaluationId === null}
            href={evaluationHref ?? undefined}
            // For some reason, we need to set onClick to undefined even when disabled is true
            onClick={
              evaluationId !== null
                ? () => {
                    analytics.track("Button Clicked", {
                      purpose: "view-evaluation",
                      location: "onboarding",
                    });
                    onClose();
                  }
                : undefined
            }
            shade="black"
            styling="solid"
            ref={evaluationButtonRef}
          >
            View Evaluation
          </Button>
        </ConditionalTooltip>
      </div>
    </div>
  );
};

export const getOnboardingSnippet = ({
  humanloopApiKey,
  openaiApiKey,
}: {
  humanloopApiKey?: string | null;
  openaiApiKey?: string | null;
} = {}) => `from humanloop import Humanloop
from openai import OpenAI

humanloop = Humanloop(api_key="${humanloopApiKey ?? "YOUR_HUMANLOOP_API_KEY"}"${API_URL_OVERRIDE === null ? "" : ', base_url="' + API_URL_OVERRIDE + '/v5"'})
openai = OpenAI(api_key="${openaiApiKey ?? "YOUR_OPENAI_API_KEY"}")

# Define your Prompt in code
model = "gpt-4o-mini"
template = [
    {"role": "user", "content": "Extract the first name for '{{full_name}}'."},
    # Uncomment the next line when running the script a second time.
    # {"role": "user", "content": "Reply only with the first name"},
]

def call_openai(**inputs) -> str:
    response = openai.chat.completions.create(
        model=model,
        messages=humanloop.prompts.populate_template(template=template, inputs=inputs),
    )
    return response.choices[0].message.content

# Runs the eval and versions the Prompt and Dataset
humanloop.evaluations.run(
    name="${ONBOARDING_EVALUATION_NAME}",
    file={
        "path": "${ONBOARDING_PROMPT_NAME}",
        "callable": call_openai,
        "type": "prompt",
        "version": {"model": model, "template": template},
    },
    dataset={
        "path": "${ONBOARDING_DATASET_NAME}",
        "datapoints": [
            {
                "inputs": {"full_name": "Albert Einstein"},
                "target": {"output": "Albert"},
            },
            {
                "inputs": {"full_name": "Albus Wulfric Percival Brian Dumbledore"},
                "target": {"output": "Albus"},
            },
        ],
    },
    evaluators=[
        {"path": "Example Evaluators/Code/Exact match"},
        {"path": "Example Evaluators/Code/Levenshtein distance"},
        {"path": "Example Evaluators/Code/Latency"},
    ],
)`;

const OpenAIApiKeyInput = ({ organizationId, onSave }: { organizationId: string; onSave: (key: string) => void }) => {
  return (
    <Formik
      initialValues={{ key: "" }}
      onSubmit={async ({ key }, { setErrors }) => {
        try {
          await updateModelProviderKeys(organizationId, [{ model_provider: "openai", key: key }]);
          onSave(key);
        } catch (e: any) {
          let error;
          try {
            error = e.response.data.detail.msg;
            if (!isString(error)) {
              error = "An error occurred";
            }
          } catch {
            error = "An error occurred";
          }
          setErrors({ key: error });
        }
      }}
    >
      <Form className="flex gap-16">
        <FormikTextInput name="key" className="w-280" placeholder="OpenAI API key" />
        <Button shade="black" styling="solid" type="submit">
          Save key
        </Button>
      </Form>
    </Formik>
  );
};
